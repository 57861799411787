@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

* {
  background-color: "transparent";
}

html {
  overflow-y: scroll;
}

input {
  accent-color: #9d1c21;
  margin-right: 5px;
}

.girar-enter {
  transform: rotate(0deg);
}

.girar-enter-active {
  transform: rotate(-180deg);
  transition: all 250ms;
}

.girar-enter-done {
  transform: rotate(180deg);
}

.girar-exit {
  transform: rotate(-180deg);
}

.girar-exit-active {
  transform: rotate(0deg);
  transition: all 100ms;
}

.opacidad-enter {
  opacity: 0;
}

.opacidad-enter-active {
  opacity: 1;
  transition: all 250ms;
}

.opacidad-exit {
  opacity: 1;
}

.opacidad-exit-active {
  opacity: 0;
  transition: all 250ms;
}

.submenu-enter {
  opacity: 0;
  transform: scale(0.95);
}

.submenu-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 250ms;
}

.submenu-exit {
  opacity: 1;
  transform: scale(1);
}

.submenu-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: all 250ms;
}

.titulo_categoria_submenu {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20;
  font-weight: 700;
  line-height: 51px;
  text-align: left;
}

.subtitulo_categoria_submenu {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 17;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
}

.categoria_submenu {
  color: #41454c;
  font-family: "Manrope";
  font-size: 14;
  font-weight: 500;
  line-height: 31px;
  text-align: left;
}

.categoria_submenu:hover {
  color: #9d1c21;
}

.textoBanner {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: calc(44 * (1536px / 1920));
  font-weight: 800;
  height: calc(200 * (1536px / 1920));
  display: flex;
  align-items: center;
  padding-left: 3.44%;
}

.textoBannerMobile {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: calc(30 * (1536px / 1920));
  font-weight: normal;
  height: calc(200 * (1536px / 1920));
  display: flex;
  align-items: center;
  max-width: calc(100% - (250 * 1536px / 1920));
  padding-left: 3.44%;
}

a {
  text-decoration: none;
  color: #9d1c21;
}

.itemNavBar {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16;
  font-weight: 500;
}

.submenuProductos:hover {
  background: #61616C;
}

.pregunta {
  color: #41454c;
  font-family: "Manrope";
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
  display: flex;
}

.respuesta {
  color: #41454c;
  font-family: "Manrope";
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
}

.pf_categoria {
  flex: 1;
  background-color: #ffffff;
  color: #1d1f23;
  font-family: "Manrope";
  font-size: 16px;
  line-height: 20px;
  height: 49px;
  border: 3px solid #e6e6e6;
  font-weight: 700;
  max-width: 200px;
}

.pf_categoria:disabled {
  background-color: #e6e6e6;
}

.tituloBottom {
  color: #4b4b4b;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 700;
}

.linkBottom {
  color: #4b4b4b;
  font-family: "Manrope";
  font-size: calc(13 * (1536px / 1920));
  line-height: calc(17.7 * (1536px / 1920));
  font-weight: 700;
}

.tituloError {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(80 * (1536px / 1920));
  font-weight: 800;
}

.textoError {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(40 * (1536px / 1920));
  font-weight: 800;
}

.tituloCalculo {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 800;
}

.selectCalculo {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 600;
}

.botonCalculo {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(24 * (1536px / 1920));
  font-weight: 600;
}

.botonCalculo:disabled {
  background-color: #ffa755;
}

.botonResultado {
  color: #ef7b10;
  background-color: #ffffff;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(24 * (1536px / 1920));
  font-weight: 600;
}

.botonResultado:hover {
  color: #ef7b10;
}

.textoResultado {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  height: calc(24.51 * (1536px / 1920));
  line-height: calc(24.51 * (1536px / 1920));
  font-weight: 600;
}

.tituloPV {
  color: #9d1c21;
  font-family: "Manrope";
  font-size: 24;
  line-height: 33px;
  font-weight: 700;
}

.selectPV {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropPV {
  color: #41454c;
  font-family: "Manrope";
  font-size: 18;
  font-weight: 500;
}

.selectRT {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropRT {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
}

.tituloProductos {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(20 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 800;
}

.nombreProducto {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 700;
}

.subnombreProducto {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: pre-line;
}

.tarjetaProducto {
  border:  solid #D1D1D1;
  border-width: calc(1 * (1536px / 1920));
  border-radius: calc(5 * (1536px / 1920));
  background-color: transparent;
  transition-duration: 150ms;
}

.tarjetaProducto:hover {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  transition-duration: 150ms;
}

.tituloDetalle {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(24 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.subtituloDetalle {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.descDetalle {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.botonManual {
  color: #ef7b10;
  background-color: #ffffff;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: calc(1 * (1536px / 1920)) solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonManual:hover {
  color: #ef7b10;
}

.botonComprar {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonComprar:hover {
  color: #ffffff;
}

.botonFiltros {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 600;
}

.filtro {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  height: calc(22 * (1536px / 1920));
  line-height: calc(22 * (1536px / 1920));
  font-weight: 600;
}

.tituloFiltro {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(50 * (1536px / 1920));
  font-weight: 800;
}

.tituloAcceso {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.campoAcceso {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
  border: calc(1 * (1536px / 1920)) solid #3f3f46;
  border-radius: calc(5 * (1536px / 1920));
}

.selectRegistro {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropRegistro {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  font-weight: 600;
}

.confirmacionRegistro {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(30 * (1536px / 1920));
  font-weight: 600;
}

.botonAcceso {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: calc(1 * (1536px / 1920)) solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Manrope";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonAcceso:hover {
  color: #ffffff;
}

.botonAcceso:disabled {
  border-color: #ffa755;
  background-color: #ffa755;
}

.textoAcceso {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.mensajeAcceso {
  border-radius: calc(5 * (1536px / 1920));
  padding: calc(5 * (1536px / 1920));
  text-align: center;
  color: #ffffff;
  font-family: "Manrope";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.tituloVerificar {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(64 * (1536px / 1920));
  font-weight: 800;
}

.mensajeVerificar {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(32 * (1536px / 1920));
  font-weight: 800;
}

.tituloClientes {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(30 * (1536px / 1920));
  line-height: calc(40.85 * (1536px / 1920));
  font-weight: 600;
}

.nombreArchivo {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(19.07 * (1536px / 1920));
  font-weight: 700;
  overflow-wrap: break-word;
}

.tituloPosventa {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(30 * (1536px / 1920));
  line-height: calc(40 * (1536px / 1920));
  font-weight: 500;
}

.subtituloPosventa {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(20 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 700;
}

.varPosventa {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
}

.campoPosventa {
  color: #3f3f46;
  font-family: "Manrope";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.scrollSinBarra {
  scroll-behavior: smooth;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.scrollSinBarra::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.footer_categoria {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.footer_subcategoria {
  color: #cccbcb;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 13;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
}

.footer_subcategoria:hover {
  color: #ffffff;
}

.footer_red {
  fill: #cccbcb
}

.footer_red:hover {
  fill: #FFFFFF
}

.footer_copy {
  color: #cccbcb;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer_legal {
  color: #cccbcb;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 26;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.footer_legal:hover {
  color: #FFFFFF
}

.select {
  padding: 22;
  color: #41454c;
  font-family: "Manrope";
  font-size: 18;
  line-height: 21px;
  font-weight: 500;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.select_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.select_drop {
  color: #41454c;
  font-family: "Manrope";
  font-size: 18;
  font-weight: 500;
  height: 56;
  line-height: 50px;
}

.select_boton {
  background-color: #9d1c21;
  height: 56;
  margin-top: 29;
  line-height: 56px;
  color: #FFFFFF;
  font-family: "Manrope";
  font-size: 20;
  font-weight: 700;
  text-align: center;
  padding-left: 22px;
  padding-right: 22px;
  border: none;
  border-radius: 3px;
}

.select_boton:hover {
  background-color: #a73338;
}

.select_boton:disabled {
  background-color: #e6797f;
}

.contacto_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 58px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
}

.contacto_texto {
  color: #000000;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
}

.legal_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  margin-bottom: 20px;
}

.legal_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.legal_espacio_parrafo {
  width: 100%;
  height: 18px;
}

.legal_separador {
  background-color: #c3c3c3;
  width: 100%;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.politica_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  margin-bottom: 40px;
}

.politica_titulo_top {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  margin-bottom: 40px;
}

.politica_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.politica_texto_top {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
}

.politica_espacio_parrafo {
  width: 100%;
  height: 18px;
}

.politica_separador {
  background-color: #c3c3c3;
  width: 100%;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.rrhh_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
}

.rrhh_espacio_parrafo {
  width: 100%;
  height: 24px;
}

.rrhh_recuadro {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
}

.rrhh_recuadro_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  margin-bottom: 50px;
}

.rrhh_recuadro_texto {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.rrhh_recuadro_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 800;
  line-height: 56px;
  padding-left: 70px;
  padding-right: 70px;
  border-radius: 4px;
  text-align: center;
  margin-top: 70px;
}

.rrhh_recuadro_boton:hover {
  background-color: #a73338;
}

.cv_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 35px;
  font-weight: 800;
  line-height: 73px;
  text-align: left;
  margin-bottom: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.cv_campo {
  height: 80px;
  width: 100%;
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  border-radius: 7px;
  border: 1px solid #cccbcb;
  padding-left: 25px;
  padding-right: 25px;
}

.cv_archivo_boton {
  height: 200px;
  width: 100%;
  color: #cccbcb;
  background-color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #cccbcb;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  gap: 8px;
}

.cv_campo_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  width: 100%;
  flex: 1;
}

.cv_aclaracion {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
}

.cv_boton {
  background-color: #9d1c21;
  border-radius: 4px;
  padding-left: 52px;
  padding-right: 52px;
  color: #ffffff;
  border: none;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 800;
  line-height: 56px;
  text-align: center;
}

.cv_boton:hover {
  background-color: #a73338;
}

.cv_boton:disabled {
  background-color: #e6797f
}

.gracias_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 52px;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}

.gracias_mensaje1 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 300;
  line-height: 43px;
  text-align: center;
}

.gracias_mensaje1_rojo {
  color: #9d1c21;
  font-weight: 500;
}

.gracias_tipo {
  color: #2f3236;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
}

.gracias_nro {
  background-color: #9d1c21;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
}

.gracias_mensaje2 {
  color: #2E3137;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
  text-align: center;
}

.gracias_aclaracion {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 200;
  line-height: 33px;
  text-align: center;
}

.comprar_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 52px;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}

.comprar_mensaje1 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 300;
  line-height: 43px;
  text-align: center;
}

.comprar_mensaje1_rojo {
  color: #9d1c21;
  font-weight: 500;
}

.comprar_mensaje2 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 43px;
  text-align: left;
  width: 100%;
}

.comprar_container_mail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 60px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.comprar_mail {
  width: 100%;
  max-width: 550px;
  background-color: #e9e9e9;
  border: none;
  height: 50px;
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  line-height: 43px;
  padding: 10px;
}

.comprar_boton_mail {
  background-color: #9d1c21;
  color: #ffffff;
  border: 1px #9d1c21 solid;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  width: 220px;
  height: 64px;
  text-align: center;
}

.comprar_boton_mail:hover {
  background-color: #a73338;
  border-color: #a73338;
}

.comprar_boton_mail:disabled {
  background-color: #e6797f;
  border-color: #e6797f;
}

.comprar_boton_mail_enviado {
  background-color: transparent;
  border: none;
  width: 220px;
  height: 64px;
  text-align: center;
}

.comprar_container_final {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 60px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.comprar_mensaje3 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  width: 100%;
  max-width: 550px;
}

.comprar_boton_home {
  background-color: #ffffff;
  color: #9d1c21;
  border: 1px #9d1c21 solid;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  width: 220px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.comprar_boton_home:hover {
  color: #ffffff;
  background-color: #9d1c21;
}

.ingreso_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

.ingreso_texto1 {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
}

.ingreso_texto2 {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.ingreso_recuadro {
  background-color: #ffffff;
  padding: 40px;
  width: 366px;
  margin-left: auto;
}

.ingreso_recuadro_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  margin-bottom: 30px;
}

.ingreso_campo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #5a5a5a;
  width: 100%;
  margin-bottom: 30px;
}

.ingreso_cambio {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  height: 16px;
  text-align: left;
  margin-bottom: 15px;
}

.ingreso_mensaje {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  height: auto;
  text-align: left;
  margin-bottom: 5px;
}

.ingreso_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  width: 100%;
  text-align: center;
}

.ingreso_boton:hover {
  background-color: #a73338;
}

.ingreso_boton:disabled {
  background-color: #e6797f;
}

.ingreso_registrarse {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
}

.ingreso_registrarse_boton {
  background-color: transparent;
  color: #ffffff;
  border: 1px #ffffff solid;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  line-height: 41px;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ingreso_registrarse_boton:hover {
  background-color: #ffffff;
  color: #41454c
}

.registro_titulo {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.registro_texto1 {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.registro_texto2 {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.registro_recuadro {
  background-color: #ffffff;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 90px;
  padding-right: 90px;
  max-width: 463px;
  margin-left: auto;
}

.registro_recuadro_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  margin-bottom: 30px;
}

.registro_campo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #5a5a5a;
  width: 100%;
  margin-bottom: 24px;
}

.registro_pregunta {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
}

.registro_check {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  width: 100%;
  margin-bottom: 24px;
}

.registro_check_comerciante {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 15.5px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 12px;
}

.registro_select {
  color: #41454c;
  font-family: "Manrope";
  font-size: 18;
  line-height: 21px;
  font-weight: 500;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.registro_mensaje {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  height: 16px;
  text-align: left;
  margin-bottom: 5px;
}

.registro_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  width: 100%;
  text-align: center;
}

.registro_boton:hover {
  background-color: #a73338;
}

.registro_boton:disabled {
  background-color: #e6797f;
}

.construccion_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}

.construccion_boton {
  background-color: transparent;
  color: #9d1c21;
  border: 1px #9d1c21 solid;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  line-height: 64px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.construccion_boton:hover {
  background-color: #9d1c21;
  color: #ffffff;
}

.posventa_recuadro_boton:hover {
  background-color: #9d1c21;
  color: #ffffff;
}

.concurso_titulo1 {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 34px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
}

.concurso_titulo2 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
}

.concurso_recuadro {
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.concurso_recuadro_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.concurso_recuadro_titulo {
  color: #9d1c21;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 16px
}

.concurso_campo_titulo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  width: 100%;
  flex: 1;
  margin-bottom: 24px;
}

.concurso_campo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 22px;
  font-weight: 400;
  height: 55px;
  padding: 10px;
  text-align: left;
  border: 1px solid #cccbcb;
  border-radius: 7px;
  width: 100%;
  min-width: 250px;
}

.concurso_campo_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.concurso_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  width: 200px;
  text-align: center;
}

.concurso_boton:hover {
  background-color: #a73338;
}

.concurso_boton:disabled {
  background-color: #e6797f;
}

.concurso_tyc {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  display: "flex";
  align-content: center;
  align-items: center;
}

.garantia_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.garantia_proximamente {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
}

.garantia_texto1 {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.garantia_texto2 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
}

.garantia_boton {
  background-color: transparent;
  color: #9d1c21;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  width: 255px;
  text-align: center;
}

.garantia_boton:hover {
  background-color: #9d1c21;
  color: #ffffff;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  width: 255px;
  text-align: center;
}

.garantia_recuadro {
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.garantia_recuadro_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.garantia_recuadro_titulo {
  color: #9d1c21;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 16px
}

.garantia_campo_titulo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  width: 100%;
  flex: 1;
  margin-bottom: 24px;
}

.garantia_campo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 22px;
  font-weight: 400;
  height: 55px;
  padding: 10px;
  text-align: left;
  border: 1px solid #cccbcb;
  border-radius: 7px;
  width: 100%;
  min-width: 230px;
}

.garantia_campo_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.garantia_recuadro_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  width: 200px;
  text-align: center;
}

.garantia_recuadro_boton:hover {
  background-color: #a73338;
}

.garantia_recuadro_boton:disabled {
  background-color: #e6797f;
}

.posventa_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
}

.posventa_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: center;
}

.posventa_texto1 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.posventa_texto2 {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.posventa_boton {
  background-color: transparent;
  color: #9d1c21;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  width: 255px;
  text-align: center;
}

.posventa_boton:hover {
  background-color: #9d1c21;
  color: #ffffff;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  width: 255px;
  text-align: center;
}

.posventa_recuadro {
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.posventa_recuadro_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.posventa_recuadro_nota {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 100%;
}

.posventa_recuadro_titulo {
  color: #9d1c21;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 16px
}

.posventa_campo_titulo {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  width: 100%;
  flex: 1;
  margin-bottom: 24px;
}

.posventa_campo {
  color: #41454c;
  background-color: #ffffff;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 22px;
  font-weight: 400;
  height: 55px;
  padding: 10px;
  text-align: left;
  border: 1px solid #cccbcb;
  border-radius: 7px;
  width: 100%;
  min-width: 230px;
}

.posventa_campo_aclaracion {
  color: #41454c;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.posventa_recuadro_boton {
  background-color: #9d1c21;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  width: 200px;
  text-align: center;
}

.posventa_recuadro_boton:hover {
  background-color: #a73338;
}

.posventa_recuadro_boton:disabled {
  background-color: #e6797f;
}

.atencion_titulo {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  font-family: "Manrope";
  font-size: 36px;
  font-weight: 500;
  line-height: 49px;
  text-align: left;
}

.atencion_boton {
  flex: 1;
  width: 269px;
  height: 269px;
  position: relative;
  object-fit: cover;
  object-position: center;
  background-image: var(--my-image);
}

.atencion_boton:hover {
  background-image: var(--hover-image);
}

.sobre_nosotros_gradiente {
  background-image: linear-gradient(#781C27, #B02028, #8C1B29);
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 6vw;
  padding-bottom: 10vw;
  width: 100%;
}

.sobre_nosotros_postales {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  justify-items: center;
}

.sobre_nosotros_postales img {
  top: -50px;
}

.sobre_nosotros_calidad_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  text-align: left;
}

.sobre_nosotros_calidad_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  margin-bottom: 28px;
}

.sobre_nosotros_sustentabilidad_titulo {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  text-align: left;
  width: 100%;
  flex-shrink: 0;
}

.sobre_nosotros_sustentabilidad_subtitulo {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-bottom: 28px;
}

.sobre_nosotros_sustentabilidad_texto {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  list-style-type: disc;
  list-style-position: outside;
}

.sobre_nosotros_actualidad_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
  text-align: left;
  width: 100%;
  padding-left: 10%
}

.sobre_nosotros_actualidad_subtitulo1 {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 48px;
  font-weight: 400;
  line-height: 59px;
  text-align: left;
  margin-bottom: 12px;
}

.sobre_nosotros_actualidad_subtitulo2_padre {
  filter: url('#flt_tag');
}

.flt_svg {
  visibility: hidden; 
  position: absolute;
  width: 0px;
  height: 0px;
}

.sobre_nosotros_actualidad_subtitulo2 {
  background-image: linear-gradient(to right,#8C1B29, #B02028, #781C27);
  color: #ffffff;
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
  text-decoration: none;
  font-family: "Manrope";
  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
  text-align: center;
  width: 301px;
  margin-bottom: 3vw;
}

.sobre_nosotros_actualidad_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}

.productos_banner {
  display: flex;
  background-image: linear-gradient(to right, #1E1E1E,#6B717A,#1D1F23);
  min-height: 72px;
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
}

.productos_banner_texto {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.productos_banner_boton {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  padding-left: 44px;
  padding-right: 44px;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: center;
}

.productos_banner_boton:hover {
  background-color: #9d1c21;
  color: #ffffff;
}

.filtros_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}

.filtros_subtitulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.filtros_quitar {
  background-color: #eff1f3;
  border: 1px solid #eff1f3;
  border: none;
  border-radius: 5px;
  color: #5a5a5a;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  align-items: baseline;
  width: fit-content;
  display: flex;
  gap: 8px;
}

.filtros_quitar:hover {
  background-color: #cccbcb;
  border-color: #cccbcb;
}

.filtros_quitar:disabled {
  opacity: 0;
}

.filtros_boton {
  background-color: #eff1f3;
  border: 1px solid #eff1f3;
  border: none;
  border-radius: 5px;
  color: #5a5a5a;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  min-height: 40px;
  max-height: 56px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  width: fit-content
}

.filtros_boton:hover {
  background-color: #e8e8e9;
  border-color: #e8e8e9;
}

.filtros_boton_seleccionado {
  background-color: rgba(157,28,33,0.1);
  border: none;
  display: flex;
  gap: 8.5px;
  border-radius: 5px;
  border: 1px solid #9d1c21;
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  min-height: 40px;
  max-height: 56px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  align-content: center;
  align-items: center;
  width: fit-content
}

.producto_card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  height: 376px;
}

.producto_card:hover > div > .producto_texto1 {
  color: #9d1c21;
}

.producto_texto1{
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-top: 18px;
}

.producto_texto2{
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin-top: 6px;
}

.producto_texto3{
  color: #737882;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}

.home_producto_texto1{
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
}

.home_producto:hover > div > .home_producto_texto1 {
  color: #9d1c21
}

.home_producto_texto2{
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.detalle_links {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.detalle_links a {
  color: #1D1F23;
}

.detalle_links a:hover {
  color: #9d1c21;
}

.detalle_modelo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  margin-bottom: 19px;
}

.detalle_descripcion {
  color: #1D1F23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-bottom: 34px;
}

.detalle_calidad { 
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.detalle_calidad_texto {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 33px;
  list-style-type: disc;
  list-style-position: outside;
}

.detalle_variantes_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  margin-bottom: 7px;
}

.detalle_variantes_subtitulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}

.detalle_variantes_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: left;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: "100%";
  margin-bottom: 37px;
}

.detalle_variantes_boton {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border-width: 1.5px;
  border-color: #41454c;
}

.detalle_sellos_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: "100%";
  margin-bottom: 11px;
}

.detalle_legal {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
  margin-bottom: 16px;
}

.detalle_botones_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.detalle_boton {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  width: 220px;
  height: 64px;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: center;
}

.detalle_boton:hover {
  color: #FFFFFF;
  background-color: #9d1c21;
}

.detalle_boton_comprar {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  width: 220px;
  height: 64px;
  border: 1px solid #9d1c21;
  border-radius: 4px;
  background-color: #9d1c21;
  text-align: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.detalle_boton_comprar:hover {
  color: #ffffff;
  background-color: #a73338;
}

.detalle_discontinuado {
  color: #737882;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  text-align: left;
  margin-top: 16px;
}

.detalle_seccion {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  width: 100%;
  margin-bottom: 4vw;
}

.detalle_tabla {
  display: flex;
  flex-wrap: wrap;
  background-image: linear-gradient(180deg, #f5f6f8 50%, #ffffff 50%);
  background-repeat: repeat;
  background-size: 216px 216px;
}

.detalle_campo {
  width: 25%;
  min-width: 200px;
  height: 108px;
  padding-top: 29px;
  padding-bottom: 29px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.detalle_campo_nombre {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 21px;
  font-weight: 500;
  line-height: 24px;
  width: auto;
  flex-shrink: 0;
  text-align: left;
}

.detalle_campo_valor {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  width: auto;
  flex-shrink: 0;
  text-align: left;
}

.detalle_aclaracion {
  color: #737882;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.home_carousel_dot {
  width: 51px;
  height: 3px;
  background-color: #d9d9d9;
  border: none;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 24px;
  align-self: center;
}

.home_carousel_dot_activo {
  width: 51px;
  height: 5px;
  background-color: #9d1c21;
  border: none;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 24px;
  align-self: center;
}

.home_nosotros_titulo {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 800;
  line-height: 37px;
  text-align: left;
  width: 100%;
  flex-shrink: 0;

}

.home_nosotros_subtitulo {
  color: #000000;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 40px;
  font-weight: 300;
  line-height: 44px;
  text-align: left;
  width: 100%;
  flex-shrink: 0;

}

.home_nosotros_texto {
  color: #000000;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  width: 100%;
  flex-shrink: 0;
}

.home_nosotros_link {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
}

.home_nosotros_boton {
  color: #ffffff;
  background-color: #9d1c21;
  border: none;
  border-radius: 2px;
  padding-left: 47px;
  padding-right: 47px;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 800;
  height: 56px;
  text-align: center;
  margin-top: 0px;
}

.home_nosotros_boton:hover {
  background-color: #a73338;
}

.home_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  text-align: center;
}

.home_texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.home_calculo {
  display: flex;
  background-image: linear-gradient(to right, #642028,#B02028,#642028);
  min-height: 72px;
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home_calculo_texto {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.home_calculo_boton {
  color: #9d1c21;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  padding-left: 33px;
  padding-right: 33px;
  border: none;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: center;
}

.home_calculo_boton:hover {
  background-color: #f5f6f8;
}

.home_imagen {
  transition-duration: 80ms;
  position: relative;
  flex-grow: 0;
  flex: 1;
  object-fit: fit;
  object-position: center;
  min-width: 300px;
  max-width: 100%;
  height: auto;
  margin: auto;
  z-index: 0;
}

.home_imagen:hover {
  transform: scale(1.02);
  transition-duration: 100ms;
}

.calculo_titulo {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.calculo_recuadro {
  position: relative;
  max-width: 900px;
  width: 80%;
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: space-between;
  gap: 40px;
  padding-left: 43px;
  padding-right: 43px;
  padding-top: 25px;
  padding-bottom: 25px;
  height: auto;
  margin: auto;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.calculo_campo_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  min-width: 250px;
  text-align: left;
  margin-bottom: 6px;
}

.calculo_campo_subtitulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.calculo_campo {
  height: 28px;
  width: 100%;
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  border-radius: 2px;
  border: 1px solid #cccbcb;
  padding-left: 12px;
  padding-right: 12px;
}

.calculo_radio {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  justify-self: left;
}

.calculo_boton {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  border: none;
  border-radius: 3px;
  background-color: #9d1c21;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
  align-self: flex-end;
  min-width: 200px;
}

.calculo_boton:hover {
  background-color: #a73338;
}

.calculo_boton:disabled {
  background-color: #e6797f
}

.calculo_boton_calefaccion {
  color: #ffffff;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  width: "100%";
  min-width: 250px;
  height: 30px;
  border: none;
  border-radius: 3px;
  background-color: #9d1c21;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.calculo_boton_calefaccion:hover {
  background-color: #a73338;
}

.calculo_boton_calefaccion:disabled {
  background-color: #e6797f
}

.calculo_aclaracion {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  min-width: 250px;
  text-align: left;
  justify-self: left;
}

.calculo_resultado {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 300;
  line-height: 49px;
  text-align: center;
  width: 100%;
  margin-bottom: 2vw;
}

.comerciantes_titulo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.comerciantes_archivo {
  color: #41454c;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.texto {
  color: #1d1f23;
  text-decoration: none;
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.w-full {
  width: 100%
}